var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            keyboard: false,
            bodyStyle: {
              padding: "10px"
            },
            width: 1500
          },
          on: { cancel: _vm.cancel },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c("div", { staticClass: "footer" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: {
                              "default-value": 0,
                              "button-style": "solid"
                            },
                            model: {
                              value: _vm.imageType,
                              callback: function($$v) {
                                _vm.imageType = $$v
                              },
                              expression: "imageType"
                            }
                          },
                          [
                            _c("a-radio-button", { attrs: { value: 0 } }, [
                              _vm._v(" 正面图 ")
                            ]),
                            _c("a-radio-button", { attrs: { value: 1 } }, [
                              _vm._v(" 背面图 ")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "a-select",
                          {
                            staticStyle: { margin: "5px", width: "180px" },
                            attrs: {
                              dropdownMatchSelectWidth: false,
                              "filter-option": _vm.untils.filterOption,
                              allowClear: "",
                              showSearch: "",
                              size: "small",
                              placeholder: "请选择小币种"
                            },
                            on: { change: _vm.changeCoinItem },
                            model: {
                              value: _vm.curItem.coinKindItemId,
                              callback: function($$v) {
                                _vm.$set(_vm.curItem, "coinKindItemId", $$v)
                              },
                              expression: "curItem.coinKindItemId"
                            }
                          },
                          _vm._l(_vm.coinItemList, function(item) {
                            return _c(
                              "a-select-option",
                              { key: item.id, attrs: { value: item.id } },
                              [_vm._v(_vm._s(item.coinKindItemName))]
                            )
                          }),
                          1
                        ),
                        _c(
                          "a-select",
                          {
                            staticStyle: { margin: "5px", width: "180px" },
                            attrs: {
                              dropdownMatchSelectWidth: false,
                              allowClear: "",
                              showSearch: "",
                              "filter-option": _vm.untils.filterOption,
                              size: "small",
                              dropdownMenuStyle: { "max-height": "500px" },
                              placeholder: "版别"
                            },
                            on: { change: _vm.changeVersion },
                            model: {
                              value: _vm.curItem.versionId,
                              callback: function($$v) {
                                _vm.$set(_vm.curItem, "versionId", $$v)
                              },
                              expression: "curItem.versionId"
                            }
                          },
                          _vm._l(_vm.versionList, function(item) {
                            return _c(
                              "a-select-option",
                              { key: item.id, attrs: { value: item.id } },
                              [_vm._v(_vm._s("" + item.coinKindVersionName))]
                            )
                          }),
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "refactor-version" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleRefactorVersion()
                                  }
                                }
                              },
                              [_vm._v("重新对版")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "refactor-version" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleGetMoreVersion()
                                  }
                                }
                              },
                              [_vm._v("更多版别")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "footer-btn" },
                      [
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.cancel()
                              }
                            }
                          },
                          [_vm._v("关闭")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "body-top" },
              [
                _vm.originalInfo.sameParagraphSize > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "item-info go-to",
                        on: {
                          click: function($event) {
                            return _vm.handleSameCurrencyList()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.originalInfo.sameParagraphSize + "个相似版别"
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "a-button",
                  {
                    staticClass: "setup-btn",
                    style: {
                      "margin-left":
                        _vm.originalInfo.sameParagraphSize > 0 ? "10px" : ""
                    },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.handleMatchSameCurrency }
                  },
                  [_vm._v("匹配同款币")]
                ),
                _vm.imageType == 0
                  ? _c(
                      "a-button",
                      {
                        staticClass: "setup-btn",
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          disabled: this.frontCollect == 1,
                          size: "small",
                          type: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.badcaseCollect(
                              _vm.originalInfo.recordId,
                              _vm.imageType
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            this.frontCollect == 1
                              ? "正面已收集"
                              : "收集正面裁剪badcase"
                          ) + " "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.imageType == 1
                  ? _c(
                      "a-button",
                      {
                        staticClass: "setup-btn",
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          disabled: this.backCollect == 1,
                          size: "small",
                          type: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.badcaseCollect(
                              _vm.originalInfo.recordId,
                              _vm.imageType
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            this.backCollect == 1
                              ? "背面已收集"
                              : "收集背面裁剪badcase"
                          ) + " "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "con-body" }, [
              _c(
                "div",
                { staticClass: "c-right" },
                _vm._l(_vm.showImages, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "image-view",
                      class: {
                        bg: item.type == 1
                      }
                    },
                    [
                      item.showStatus
                        ? _c("div", { staticClass: "c-info" }, [
                            _c("img", {
                              attrs: { src: _vm.versionImage },
                              on: { click: _vm.previewImg }
                            }),
                            _c(
                              "div",
                              { staticClass: "item-info" },
                              [
                                _c(
                                  "a-popover",
                                  {
                                    attrs: {
                                      title: "版别描述",
                                      trigger: "hover"
                                    }
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "content" },
                                      [
                                        _c("a-input", {
                                          attrs: {
                                            size: "small",
                                            type: "textarea",
                                            placeholder: _vm.versionDescType
                                              ? "内部版别描述"
                                              : "外部版别描述",
                                            rows: 5,
                                            value: _vm.versionDescType
                                              ? _vm.recordList[index]
                                                  .internalMark
                                              : _vm.recordList[index]
                                                  .versionDesc
                                          },
                                          on: { change: _vm.inputVersionDesc }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dis-flex-start mt-10"
                                          },
                                          [
                                            _c(
                                              "a-radio-group",
                                              {
                                                attrs: {
                                                  "default-value": 1,
                                                  "button-style": "solid",
                                                  size: "small"
                                                },
                                                model: {
                                                  value: _vm.versionDescType,
                                                  callback: function($$v) {
                                                    _vm.versionDescType = $$v
                                                  },
                                                  expression: "versionDescType"
                                                }
                                              },
                                              [
                                                _c(
                                                  "a-radio-button",
                                                  { attrs: { value: 1 } },
                                                  [_vm._v("内部标记")]
                                                ),
                                                _c(
                                                  "a-radio-button",
                                                  { attrs: { value: 0 } },
                                                  [_vm._v("外部标记")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-button",
                                              {
                                                staticClass: "setup-btn",
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                },
                                                attrs: {
                                                  size: "small",
                                                  type: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editVersionDesc(
                                                      _vm.recordList[index]
                                                        .versionId
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.versionDescType
                                                      ? "修改内部"
                                                      : "修改外部"
                                                  ) + " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.recordList[index].internalMark
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.recordList[
                                                index
                                              ].internalMark.substr(0, 11)
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    !_vm.recordList[index].internalMark
                                      ? _c("div", [_vm._v("版别介绍编辑")])
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._l(item.list, function(item2, index2) {
                        return _c(
                          "div",
                          { key: index2, staticClass: "image-item" },
                          [
                            _c("div", { staticClass: "image-box" }, [
                              _c("img", {
                                staticClass: "image",
                                attrs: { src: item2.url }
                              }),
                              _c("div", { staticClass: "image-box-fixed2" }, [
                                _c("div", [_vm._v(_vm._s(item2.ranking))])
                              ]),
                              _c("div", { staticClass: "image-box-fixed" }, [
                                _c("div", [_vm._v(_vm._s(item2.id))]),
                                _c("div", [_vm._v(_vm._s(item2.distance))])
                              ])
                            ]),
                            _c("div", { staticClass: "item-info" }, [
                              _c("div", [_vm._v(_vm._s(item2.versionName))]),
                              _vm.pageType != 1
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("tagNameFilter")(item2.tagNames)
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      }),
                      _c("div", { staticClass: "btn-box-center" }, [
                        _vm.pageType == 1
                          ? _c(
                              "div",
                              { staticClass: "item-btn" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticClass: "setup-btn",
                                    staticStyle: {
                                      "background-color": "white",
                                      border: "none",
                                      color: "black"
                                    },
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.partScorePage(
                                          _vm.recordList[index].recordId,
                                          _vm.recordList[index].versionId,
                                          index,
                                          "up"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("上一组")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.pageType == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "item-btn",
                                staticStyle: { "margin-top": "20px" }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticClass: "setup-btn",
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.pushStore(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.type == 1
                                          ? "确认版别"
                                          : "推送到底库"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.pageType == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "item-btn",
                                staticStyle: { "margin-top": "20px" }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticClass: "setup-btn",
                                    staticStyle: {
                                      "background-color": "white",
                                      border: "none",
                                      color: "black"
                                    },
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.partScorePage(
                                          _vm.recordList[index].recordId,
                                          _vm.recordList[index].versionId,
                                          index,
                                          "down"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("下一组")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        { staticClass: "delete-current-img-list ml-20" },
                        [
                          _c("a-button", {
                            attrs: { size: "small", icon: "close-circle" },
                            on: {
                              click: function($event) {
                                return _vm.handleDeleteCurrentImgList(
                                  _vm.recordList[index].recordId
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ])
        ]
      ),
      _c("ModalPushStore", {
        ref: "push",
        on: {
          success: _vm.success,
          versionSuccess: function($event) {
            return _vm.handleVersionSuccess($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }