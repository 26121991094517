// answerStatus:1
// answers:[{answerItemName: "折十", answerTime: "2023-05-06 10:01:37", tagStatus: null, answerUserName: "莫莫",…}]
// coinName:"崇宁重宝"
// confirmItemName:null
// confirmVersionName:null
// createTime:"2023-05-06 10:00:50"
// duibanId:835865
// operateStatus:null
// questionId:78
// revealImageUrl:"https://image.douquan.com/test/api/user/coin/2023-03-08/1678259428148107.jpg?x-oss-process=image/auto-orient,1"
// showImageUrl:"https://image.douquan.com/test/api/user/coin/2023-03-08/1678259427832904.jpg?x-oss-process=image/auto-orient,1"
export const tableListData = [
    { title: "问题ID", align: "center",scopedSlots: { customRender: "questionId" }, },
    { title: "图片", align: "center", scopedSlots: { customRender: "itemImage" }, width: 200 },
    { title: "对版TOP5", align: "center", scopedSlots: { customRender: "itemVersionTop" }, width: 230 },
    { title: "确认版别", align: "center", scopedSlots: { customRender: "itemConfirmVersion" }, width: 80 },
    { title: "问题来源", align: "center", scopedSlots: { customRender: "itemQuestionSourceTypeSlot" }, width: 120 },
    { title: "回答结果", align: "center",  scopedSlots: { customRender: "itemAnswerList" }  },
    { title: "处理状态", align: "center",  scopedSlots: { customRender: "itemOperateStatus" }  },
    { title: "操作", align: "center", scopedSlots: { customRender: "action" },},
]
// answerItemName:"折十"
// answerTime:"2023-05-06 10:01:37"
// answerUserName:"莫莫"
// answerVersionName:"正字隔轮阔通"
// tagStatus:null
export const sonTableListData = [
    { title: "类型", align: "center",scopedSlots: { customRender: "sonItemAnswerStatus" }, width: 100 },
    { title: "标签", align: "center",scopedSlots: { customRender: "sonItemTagType" }, width: 100},
    { title: "确版结果", align: "center",scopedSlots: { customRender: "sonItemVersionResult" }, width: 200 },
    { title: "RES", align: "center", scopedSlots: { customRender: 'sonItemAnswerUserNameSlot'} },
    { title: "回答时间", align: "center",dataIndex: "answerTime" },
    { title: "操作", align: "center", scopedSlots: {customRender: 'sonItemAction'} },
]
// 0 待处理   -1 搁置  10 进样本库  20 进底库
export const operateStatusListData = [
    {value: -1, name: '搁置'},
    {value: 0, name: '待处理'},
    {value: 3, name: '已处理'},
    {value: 4, name: '假币'},
    {value: 6, name: '无法分辨'},
    {value: 10, name: '进样本库'},
    {value: 20, name: '进底库'},
]
// 标签状态: -1 假币  2 看不清   4 不是本身  6 图片不规范
export const tagTypeListData = [
    {value: -1, name: '假币'},
    {value: 2, name: '看不清'},
    {value: 4, name: '不是本身'},
    {value: 6, name: '图片不规范'}
]

/** search-operateStatusReturnText */
export const operateStatusReturnText = (value) => {
    return operateStatusListData.find(el => el.value === value).name
}