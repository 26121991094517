<template>
  <div>
    <a-table
        :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
      <div
          slot="questionId"
          slot-scope="row"
      >
        <div>{{ row.questionId }}</div>
        <div class="link-text" @click="handleGoToVersionList(row.duibanId)">({{ row.duibanId }})</div>
        <div @click="handleSameCurrencyList(row)" v-if="row.sameId" class="color-red cursor-pointer">（{{ row.sameId }}）</div>
      </div>
      <div
          slot="itemImage"
          slot-scope="row"
      >
        <div class="item-image-list">
          <img class="item-image" :src="row.showImageUrl" @click="handleShowBigImage(row, 0)">
          <img class="item-image" :src="row.revealImageUrl" @click="handleShowBigImage(row, 1)">
        </div>
      </div>
<!--   对版top5   -->
      <div slot="itemVersionTop" slot-scope="row">
        <div
            class="version-top"
            :class="item.bgColor"
            v-for="(item, index) in row.machineList"
            :key="index">
          [{{item.dikuVersionCount}}]{{ item.itemName + '·' + item.versionName + `（${item.distance}）` }}
        </div>
      </div>
<!--      问题来源    -->
      <div slot="itemQuestionSourceTypeSlot" slot-scope="row">
        <div :class="sourceTypeClassType[row.sourceType]">{{ computedSourceTypeMapText(row.sourceType) }}</div>
      </div>
<!--   确定版别   -->
      <div slot="itemConfirmVersion" slot-scope="row" class="color-green">
        <div v-if="row.confirmItemName || row.confirmVersionName">
          <span v-if="row.confirmItemName">{{ row.confirmItemName }}</span>
          <span v-if="row.confirmItemName">·</span>
          <span v-if="row.confirmVersionName">{{ row.confirmVersionName }}</span>
        </div>
        <div v-else>-</div>
      </div>
      <!--  回答列表  -->
      <div
          slot="itemAnswerList"
          slot-scope="row"
      >
        <a-table
            :columns="sonTableList"
            :data-source="row.answers"
            :pagination="false"
        >
          <div slot="sonItemAnswerStatus" slot-scope="sonRow">
            <span class="">{{ sonRow.answerType === '正常回答' ? '正常类型' : sonRow.answerType ? sonRow.answerType : '-' }}</span>
          </div>
          <div slot="sonItemTagType" slot-scope="sonRow">
<!--            -1 假币  2 看不清   4 不是本身  6 图片不规范-->
            <span v-if="sonRow.tagStatus === -1" class="color-red">假币</span>
            <span v-else-if="sonRow.tagStatus === 2" class="color-red">看不清</span>
            <span v-else-if="sonRow.tagStatus === 4" class="color-red">不是本身</span>
            <span v-else-if="sonRow.tagStatus === 6" class="color-red">图片不规范</span>
            <span v-else>-</span>
          </div>
<!--      回答老师    -->
          <div slot="sonItemAnswerUserNameSlot" slot-scope="sonRow">
            <a-tooltip>
              <template #title>
                <span>{{ sonRow.answerUserName + ' ' + sonRow.answerUserId }}</span>
              </template>
              <span class="cur-pot color-blue">{{ sonRow.answerUserId }}</span>
            </a-tooltip>
          </div>
          <div slot="sonItemVersionResult" slot-scope="sonRow" class="color-blue">
            <div v-if="sonRow.answerItemName || sonRow.answerVersionName">
              <span v-if="sonRow.answerItemName">{{ sonRow.answerItemName }}</span>
              <span v-if="sonRow.answerItemName">·</span>
              <span v-if="sonRow.answerVersionName">{{ sonRow.answerVersionName }}</span>
            </div>
            <div v-else>-</div>
            <div class="mt-10" v-if="row.sourceType === 80">
              <a-button
                  size="small"
                  type="primary"
                  @click="handleChangeRatingDuibanVersionName(row, sonRow)"
              >修改评级确版</a-button>
            </div>
          </div>
<!--      子组件表格    -->
          <div slot="sonItemAction" slot-scope="sonRow">
            <div class="action-btn">
              <a-button
                  size="small"
                  type="primary"
                  class="setup-btn mt-10"
                  @click="handleAuctionBtn(row, 2, sonRow)"
                  :disabled="(!!row.confirmVersionName || !sonRow.answerVersionName)"
              >确认到样本库</a-button>
              <a-button
                  size="small"
                  type="primary"
                  class="setup-btn mt-10"
                  :disabled="(!!row.confirmVersionName || !sonRow.answerVersionName)"
                  @click="handleAuctionBtn(row, 3, sonRow)"
              >确认到标库 <span v-if="sonRow.dikuVersionCount">[{{sonRow.dikuVersionCount}}]</span></a-button>
            </div>
          </div>
        </a-table>
      </div>
      <!--  处理状态  -->
      <div slot="itemOperateStatus" slot-scope="row">
        <span v-if="row.operateStatus === 0 || row.operateStatus == null" class="color-red">待处理</span>
        <span v-if="row.operateStatus === -1" class="color-orange">搁置</span>
        <span v-if="row.operateStatus === 10">已确认进样本库</span>
        <span v-if="row.operateStatus === 20">已确认进标库</span>
        <span v-if="row.operateStatus === 4" class="color-6699ff">假币</span>
        <span v-if="row.operateStatus === 6" class="color-6699ff">无法分辨</span>
        <span v-if="row.operateStatus === 3" class="color-gray">已处理</span>
      </div>
      <!--  操作  -->
      <div slot="action" slot-scope="row">
        <div class="action-btn">
          <a-button
              size="small"
              type="primary"
              class="setup-btn"
              @click="handleAuctionBtn(row, 1)"
          >原图对版</a-button>
          <a-popconfirm
              title="确定假币吗？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleFalseCoinClick(row, 4)"
          >
            <a-button
                size="small"
                class="setup-btn mt-10"
            >假币</a-button>
          </a-popconfirm>
          <a-popconfirm
              title="确定吗？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleFalseCoinClick(row, 6)"
          >
            <a-button
                size="small"
                class="setup-btn mt-10"
            >无法辨认</a-button>
          </a-popconfirm>
          <a-popconfirm
              title="确定搁置吗？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleAuctionBtn(row, 4)"
          >
            <a-button
                size="small"
                class="setup-btn mt-10"
            >搁置</a-button>
          </a-popconfirm>
          <a-popconfirm
              title="确定标记已处理吗？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleFalseCoinClick(row, 3)"
          >
            <a-button
                size="small"
                class="setup-btn mt-10"
            >已处理</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
<!--  原图对版  -->
    <PictureVersionPopup ref="pictureVersionPopupEl"/>
<!--  原图对版v2  -->
    <PictureVersionPopupV2 ref="pictureVersionPopupV2El"/>
<!--  推送到地库  -->
    <PushVersionStorePopup
        ref="pushVersionStorePopupEl"
        @success="handleStoreSuccess"
    />
<!--  推送到样本库  -->
    <PushVersionSourceStorePopup
        ref="pushVersionSourceStorePopupEl"
        @success="handleStoreSuccess"
    />
<!--  编辑确版版别  -->
    <AgainChangeRatingVersionPopup
      ref="againChangeRatingVersionPopupEl"
      @success="handleStoreSuccess"
    />
  </div>
</template>
<script>
import {sonTableListData, tableListData} from "@/views/cmsPage/versionManage/duibanquestionanswer/_data";
import PictureVersionPopup from "@/views/cmsPage/versionManage/duibanquestionanswer/_components/PictureVersionPopup";
import PushVersionStorePopup
  from "@/views/cmsPage/versionManage/duibanquestionanswer/_components/PushVersionStorePopup";
import PushVersionSourceStorePopup
  from "@/views/cmsPage/versionManage/duibanquestionanswer/_components/PushVersionSourceStorePopup";
import PictureVersionPopupV2 from "@/views/cmsPage/versionManage/duibanquestionanswer/_components/PictureVersionPopupV2"
import {computedSourceType, sourceTypeClassType} from "@/views/cmsPage/versionManage/_data"
import AgainChangeRatingVersionPopup
  from "@/views/cmsPage/versionManage/duibanquestionanswer/_components/AgainChangeRatingVersionPopup"

export default {
  components: {
    PictureVersionPopup, PushVersionStorePopup, PushVersionSourceStorePopup,
    PictureVersionPopupV2, AgainChangeRatingVersionPopup
  },
  props: ['pagination', 'list'],
  data() {
    return {
      selectedRowKeys: [],
      tableList: tableListData,
      sonTableList: sonTableListData,
      sourceTypeClassType,
      typeValueMapText: {
        3: '已处理',
        4: '假币',
        6: '无法辨认'
      }
    }
  },
  computed: {
    computedSourceTypeMapText() {
      return value => computedSourceType(value)
    }
  },
  watch: {
    list() {
      this.selectedRowKeys = [];
      this.$emit("selectTable", []);
    },
  },
  methods: {
    /** 改变评级版别编辑 */
    async handleChangeRatingDuibanVersionName(row, sonRow) {
      sonRow.coinKindId = row.coinId
      sonRow.coinKindName = row.coinName
      sonRow.coinKindSid = row.coinSid
      sonRow.coinKindItemId = sonRow.answerItemId
      sonRow.versionId = sonRow.answerVersionId
      sonRow.questionId = row.questionId
      await this.$refs.againChangeRatingVersionPopupEl.show(sonRow)
    },
    /** 同款币跳转 */
    handleSameCurrencyList(row) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqmanage/duibanrecord?sameParagraphRecordId=${row.sameId}`;
      window.open(url)
    },
    /** 假币，分辨不清 */
    async handleFalseCoinClick(row, typeValue) {
      this.$loading.show();
      const res = await this.axios(
          "/dq_admin/duibanUserRecord/counterfeitMoney",
          {
            params: { recordIds: row.duibanId, questionId: row.questionId, type: typeValue},
          }
      );
      this.$loading.hide();
      if (res.status !== '200') return;
      this.$message.success(`标记${this.typeValueMapText[typeValue]}成功`);
      await this.handleStoreSuccess()
    },
    /** 成功 */
    handleStoreSuccess() {
      this.$emit('successStore')
    },
    /** 点击操作 */
    async handleAuctionBtn(row, type, sonRow) {
      if (type === 1) {
        row.id = row.duibanId
        const foundData = row.answers.find(el => el.answerVersionId)
        if (foundData) {
          const versionIds = row.machineList.map(el => el.versionId)
          if (!versionIds.includes(foundData.answerVersionId)) {
            row.coinKindId = foundData.answerItemId
            row.versionId = foundData.answerVersionId
          }
        }
        await this.$refs["pictureVersionPopupEl"].show(row, 1, row.duibanId);
        // await this.$refs["pictureVersionPopupV2El"].show(row, 1, row.duibanId, false);
      }
      if (type === 2) {
        sonRow.coinKindId = row.coinId
        sonRow.coinKindName = row.coinName
        sonRow.coinKindSid = row.coinSid
        sonRow.coinKindItemId = sonRow.answerItemId
        sonRow.versionId = sonRow.answerVersionId
        sonRow.duibanId = row.duibanId
        sonRow.questionId = row.questionId
        await this.$refs['pushVersionStorePopupEl'].show(sonRow)
      }
      if (type === 3) {
        // kindId, kindName, itemId, versionId, versionSid
        sonRow.coinKindId = row.coinId
        sonRow.coinKindName = row.coinName
        sonRow.coinKindSid = row.coinSid
        sonRow.coinKindItemId = sonRow.answerItemId
        sonRow.versionId = sonRow.answerVersionId
        sonRow.duibanId = row.duibanId
        sonRow.questionId = row.questionId
        await this.$refs['pushVersionSourceStorePopupEl'].show(sonRow)
      }
      if (type === 4) {
        const res = await this.axios("/dq_admin/duibanPushQuestion/questionOperate", {
          params: { questionId: row.questionId, operateStatus: -1 },
        });
        if (res.status !== "200") return;
        this.$message.success('成功')
        this.$emit('successStore')
      }
    },
    /** table选中 */
    onSelectChange(keys, arr) {
      console.log(keys, arr, 'sdfjlk')
      this.selectedRowKeys = keys;
      this.$emit("selectTable", arr);
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 去往对版列表 */
    handleGoToVersionList(id) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqmanage/duibanrecord?recordId=${id}`;
      window.open(url)
    },
    /** 放大图片 */
    handleShowBigImage(row, index) {
      this.$previewImg({
        current: index,
        list: [{img_url: row.showImageUrl}, {img_url: row.revealImageUrl}],
        baseImgField: "img_url",
        showMute: false,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.link-text {
  text-decoration: underline;
  color: #2d8cf0;
  cursor: pointer;
}
.item-image-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .item-image {
    width: 100px;
  }
}
.color-red {
  color: red;
}
.color-orange {
  color: orange;
}
.color-green {
  color: green;
}
.color-blue {
  color: #2d8cf0;
}
.color-6699ff {
  color: #6699FF;
}
.red {
  color: #000c17 !important;
  background-color: #F3E5AB !important;
}
.orange {
  color: #000c17 !important;
  background-color: #FFE87C !important;
}
.green {
  color: #000c17 !important;
  background-color: #F2BB66 !important;
}
.gray {
  color: #000c17 !important;
  background-color: #E2A76F !important;
}
.gray-b {
  color: #000c17 !important;
  background-color: #EE9A4D !important;
}
.color-gray {
  color: gray;
}
.mt-10 {
  margin-top: 10px;
}
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.version-top {
  margin-bottom: 3px;
}
.cursor-pointer {
  margin-top: 3px;
  cursor: pointer;
}
</style>