<template>
  <div class="content">
    <div class="top">
       <a-input allowClear
          v-model="params.questionId"
          size='small'
          style="width:110px"
          placeholder='问题ID'
        ></a-input>
      <!-- 选择大币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          :dropdownMenuStyle="{'max-height': '350px'}"
          allowClear
          showSearch
          size="small"
          @search='searchData'
          style="margin: 5px; width: 180px"
          v-model="params.coinKindId"
          placeholder="请选择大币种"
          @change="handleChangeCoin($event)"
      >
        <a-select-option
            v-for="items of bigCoinList"
            :key="items.id"
            :value="items.id"
        >{{ items.coinKindName }}</a-select-option>
      </a-select>
      <!-- 选择小币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          @change="coinItemChange"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.coinKindItemId"
          placeholder="请选择小币种"
      >
        <a-select-option
            v-for="item of smallCoinList"
            :key="item.coinKindItemId"
            :value="item.coinKindItemId"
        >{{ item.coinKindItemName }}</a-select-option>
      </a-select>
      <!-- 选择版别 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 260px"
          v-model="params.coinVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="请选择版别"
      >
        <a-select-option
            v-for="item of versionList"
            :key="item.coinVersionId"
            :value="item.coinVersionId"
        >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}` }}</a-select-option>
      </a-select>
      <a-select
          v-if="false"
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.answerUserId"
          placeholder="回答老师"
      >
        <a-select-option
            v-for="item of answerUserList"
            :key="item.userId"
            :value="item.userId"
        >{{ item.userName}}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 100px"
          v-model="params.tagStatus"
          placeholder="标签类型"
      >
        <a-select-option
            v-for="item of tagTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name}}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 100px"
          v-model="params.operateStatus"
          placeholder="操作类型"
      >
        <a-select-option
            v-for="item of operateStatusList"
            :key="item.value"
            :value="item.value"
        >{{ item.name}}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          class="m-5 w-150"
          v-model="params.sourceType"
          placeholder="问题来源"
      >
        <a-select-option
            v-for="item of sourceTypeTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name}}</a-select-option>
      </a-select>
      <a-button
          size="small"
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-popconfirm
          title="确定要批量处理吗？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="handleAllConfirm"
      >
        <a-button
            class="ml-20"
            size="small"
            :disabled="selectedArrays.length <= 0"
        >批量已处理</a-button>
      </a-popconfirm>
    </div>
    <div class="table-content">
      <QuestionAnswerTable
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @successStore="editSuccess"
          @selectTable="handleSelectTable"
          @changeVersionSuccess="editSuccess"
      />
    </div>
  </div>
</template>
<script>
import QuestionAnswerTable from "@/views/cmsPage/versionManage/duibanquestionanswer/_components/QuestionanswerTable";
import AnswerMixin from "@/views/cmsPage/versionManage/duibanquestionanswer/_mixin/answerMixin";
import {operateStatusListData, tagTypeListData} from "@/views/cmsPage/versionManage/duibanquestionanswer/_data";
import {markTagList} from "@/views/cmsPage/versionManage/duibanrecord/data";
import untils from "@/untils";
import {sourceTypeTypeList} from "@/views/cmsPage/versionManage/_data"

export default {
  components: {
    QuestionAnswerTable
  },
  computed: {
    isDisabled() {
      return this.selectedRowKeys.length <= 0;
    },
  },
  data() {
    return {
      untils,
      sourceTypeTypeList,
      checkAll: false,
      indeterminate: false,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
        showTotal: (total) => `共${total}条`,
      },
      markTagList:markTagList,
      params: {
        pageNum: 1,
        pageSize: 5,
        coinKindId: undefined,
        coinKindItemId: undefined,
        coinVersionId: undefined,
        markTag: undefined,
        tagStatus: undefined,
        operateStatus: undefined,
        answerUserId: undefined,
        questionId: undefined,
        sourceType: undefined
      },
      bigCoinList: [],
      smallCoinList: [],
      versionList: [],
      bigCoinText: '',
      answerUserList: [],
      operateStatusList: operateStatusListData,
      tagTypeList: tagTypeListData,
      selectedRowKeys: [],
      colorMap: {
        0: 'red',
        1: 'green',
        2: 'orange',
        3: 'gray',
        4: 'gray-b'
      },
      selectedArrays: [],
    };
  },
  async mounted() {
    const { questionId } = this.$route.query;
    this.$set(this.params, "questionId", questionId);
    await this.getList()
    // await this.getUserList()
  },
  mixins: [AnswerMixin],
  methods: {
    /** 批量已处理*/
    async handleAllConfirm() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanUserRecord/counterfeitMoney", {
        params: {
          recordIds: this.selectedArrays.map(el => el.duibanId).join(','),
          type: 3
        },
      });
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('已处理标记完成')
      await this.getList()
    },
    editSuccess() {
      this.getList()
    },
    /** 选择table */
    handleSelectTable(selectedArray) {
      this.selectedArrays = JSON.parse(JSON.stringify(selectedArray));
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.params.pageNum = 1;
      this.pagination.current = 1;
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanPushAnswer/queryList", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      let list = res.data.records;

      // itemName:"折十", versionId:18589, versionName:"退崇"
      list.forEach(el => {
        try {
          const listLength = el.machineList.length
          for (let i = 0; i < listLength; i++) {
            const versionId = el.machineList[i].versionId
            el.machineList.forEach(item => {
              if (versionId === item.versionId && !item.bgColor) {
                item.bgColor = this.colorMap[i]
              }
            })
          }
        } catch (e) {}
      })
      list.forEach(item => {
        item.checkStatus = false
      })
      this.data = list;
      this.pagination.total = res.data.total;
    },
    /** 获取老师列表 */
    async getUserList() {
      const res = await this.axios("/dq_admin/duibanPushAnswer/answerUsers");
      if (res.status !== '200') return;
      this.answerUserList = res.data
    }
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
