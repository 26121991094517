<template>
  <!-- 推送样本库 -->
  <a-modal
      v-model="pushModal"
      :width='800'
      title="确认到样本库"
  >
    <template #footer>
      <a-button key="back" @click="cancelPushModal">取消</a-button>
      <a-button key="submit-wait" type="primary" @click="confirmPushModal('sampleToWaiting')">确认到到困难样本</a-button>
      <a-button key="submit-diku" type="primary" @click="confirmPushModal('sampleToRemoveDiku')">确认到样本库</a-button>
    </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoin"
            @search="coinSearch"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="curItem.coinKindId"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of coinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoinItem"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="curItem.coinKindItemId"
            placeholder="请选择小币种"
        >
          <a-select-option
              v-for="item of coinItemList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            @change="changeVersion"
            showSearch
            :filter-option="untils.filterOption"
            size="small"
            style="margin: 5px; width: 280px"
            v-model="curItem.versionId"
            :dropdownMenuStyle="{'max-height': '500px'}"
            placeholder="版别"
        >
          <a-select-option
              v-for="item of versionList"
              :key="item.id"
              :value="item.id"
          >{{ `${item.coinKindVersionName}` }}</a-select-option>
        </a-select>
      </div>
    </div>

  </a-modal>
</template>

<script>
import untils from "@/untils"
export default {
  data() {
    return {
      untils,
      pushModal: false,
      curItem: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
    };
  },
  methods: {
     async show(data) {
      this.curItem = JSON.parse(JSON.stringify(data));
      this.$loading.show()
      await this.setDefault();
      this.$loading.hide()
      this.pushModal = true;
    },
    // 默认值
    async setDefault() {
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: this.curItem.coinKindName },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
      let found
      if (this.coinList && this.coinList.length) {
        found = this.coinList.find(el => el.sid === this.curItem.coinKindSid)
      }

      this.curItem.coinKindId = found.id;
      this.curItem.coinSid = found.sid;
      this.curItem.coinKindName = found.coinKindName;
      await this.coinItemSearch();
      if (this.curItem.versionId) {
        const coinItem = this.coinItemList.find((el) => {
          return el.id == this.curItem.coinKindItemId;
        });
        try {
          this.curItem.coinKindItemId = coinItem.id;
          this.versionList = coinItem.coinItemVersionList;
          this.$set(this.curItem, "versionId", this.curItem.versionId);
          this.$set(this.curItem, "coinKindItemVersionName", this.curItem.versionName);
        } catch (e) {}
      }
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal(type) {
      const { duibanId, coinKindId, coinKindItemId, versionId, questionId } = this.curItem;
      this.$loading.show();
       const params = {
         recordId: duibanId,
         coinId: coinKindId,
         itemId: coinKindItemId,
         questionId: questionId,
         versionId,
         type: 5,
         isTest: 0
       }
       if (type === 'sampleToWaiting') {
         params.operType = type
       }
      const res = await this.axios(
          "/dq_admin/duibanUserRecord/confirmToEnterTheSampleLibrary",
          {
            params: params,
          }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$emit('success')
      this.$message.success(res.message || "操作成功");
    },

    // 大币种改变
    changeCoin(val) {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemName;
      delete this.curItem.versionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.curItem.coinSid = coin.sid;
      this.curItem.coinKindName = coin.coinKindName;

      this.coinItemSearch();
    },
    //  选择小币种
    changeCoinItem(val) {
      delete this.curItem.versionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
    },
    // 版别改变
    changeVersion(val) {
      if (!val) return;
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.versionId;
      });
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
    },
    // 删除图片
    deleteImage(index) {
      this.curItem.covers.splice(index, 1);
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: str },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
  },
};
</script>

<style lang="scss" scoped>
.checked {
  margin-top: 10px;
}
</style>